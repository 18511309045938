export default $axios => (resource, needToken = false, disableRedirectOnError = false) => ({
    index(params = null) {
        const config = {withToken: needToken, disableRedirectOnError, params}
        return $axios.get(`/${resource}`, config)
    },
    show(id, params = null) {
        const config = {withToken: needToken, disableRedirectOnError, params}
        return $axios.get(`/${resource}/${id}`, config)
    },
    create(payload, params = null) {
        const config = {withToken: needToken, disableRedirectOnError, params}
        return $axios.post(`/${resource}`, payload, config)
    },
    update(payload, params = null) {
        const config = {withToken: needToken, disableRedirectOnError, params}
        return $axios.put(`/${resource}`, payload, config)
    },
    delete(params = null) {
        const config = {withToken: needToken, disableRedirectOnError, params}
        return $axios.delete(`/${resource}`, config)
    }
})
