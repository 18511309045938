import {createStore, createLogger, Store as VuexStore, CommitOptions, DispatchOptions} from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import {store as settings, State as SettingsState} from '@/store/modules/settings/index';
import {store as catalog, State as CatalogState} from '@/store/modules/catalog/index';
import {store as favorites, State as FavoritesState} from '@/store/modules/favorites/index';
import {store as notices, State as NoticesState} from '@/store/modules/notices/index';
import {Mutations} from "@/store/modules/settings/mutations";
import {Actions} from "@/store/modules/settings/actions";

export type RootState = {
    settings: SettingsState;
    catalog: CatalogState;
    favorites: FavoritesState;
    notices: NoticesState;
};

export type Store<S = RootState> = Omit<VuexStore<S>, 'getters' | 'commit' | 'dispatch'>
    & {
    commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
        key: K,
        payload: P,
        options?: CommitOptions
    ): ReturnType<Mutations[K]>;
} & {
    dispatch<K extends keyof Actions>(
        key: K,
        payload: Parameters<Actions[K]>[1],
        options?: DispatchOptions
    ): ReturnType<Actions[K]>;
};

// Plug in logger when in development environment
const debug = process.env.NODE_ENV !== 'production';
const plugins = debug ? [createLogger({})] : [];

// Plug in session storage based persistence
plugins.push(createPersistedState({
    paths: ['favorites', 'settings']
}));

export const store = createStore({
    plugins,
    modules: {
        settings,
        catalog,
        favorites,
        notices
    },
});

export function useStore(): Store {
    return store as Store;
}

