export default ($axios) => () => ({
    async entityByUrl(slug, route, entityTypeOverride = null) {
        const entityType = entityTypeOverride || route?.meta?.type || 'page'
        const mediaType = route?.meta?.media || null

        const params = {
            entity_type: entityType,
            slug,
            saleplans_id: entityType === 'product' ? 1 : null,
            depth: entityType === 'category' ? 1:null,
            media_entity: mediaType
        }

        return $axios.get(`/url/entity`, {params})
    }
})
