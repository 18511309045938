import { MutationTree } from 'vuex';

import {initialSate, State} from './state';
import { SettingsMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
    [SettingsMutationTypes.RESET](state: S): void;
    [SettingsMutationTypes.SET_LOCALE](state: S, payload: string): void;
    [SettingsMutationTypes.SET_LOCALES](state: S, payload: []): void;
    [SettingsMutationTypes.SET_IS_LOADING](state: S, payload: boolean): void;
    [SettingsMutationTypes.SET_IS_MOBILE](state: S, payload: boolean): void;
    [SettingsMutationTypes.SET_IS_PHABLET](state: S, payload: boolean): void;
    [SettingsMutationTypes.SET_IS_TABLET](state: S, payload: boolean): void;
    [SettingsMutationTypes.SET_IS_PHONE](state: S, payload: boolean): void;
    [SettingsMutationTypes.SET_IS_PORTRAIT](state: S, payload: boolean): void;
    [SettingsMutationTypes.SET_PROFILE](state: S, payload: string): void;
    [SettingsMutationTypes.SET_ONLINE](state: S, payload: boolean): void;
    [SettingsMutationTypes.SET_IS_APP](state: S, payload: boolean): void;
    [SettingsMutationTypes.SET_FIRST_VISIT_PRODUCT](state: S, payload: boolean): void;
    [SettingsMutationTypes.SET_CHOICES_AXEPTIO](state: S, payload: any|null): void;
}

export const mutations: MutationTree<State> & Mutations = {
    [SettingsMutationTypes.RESET](state: any) {
        const s: any = initialSate
        Object.keys(s).forEach((key: string) => state[key] = s[key])
    },
    [SettingsMutationTypes.SET_LOCALE](state: State, locale: string) {
        state.locale = locale;
    },
    [SettingsMutationTypes.SET_LOCALES](state: State, locales: []) {
        state.locales = locales;
    },
    [SettingsMutationTypes.SET_IS_LOADING](state: State, isLoading: boolean) {
        state.isLoading = isLoading;
    },
    [SettingsMutationTypes.SET_IS_MOBILE](state: State, isMobile: boolean) {
        state.isMobile = isMobile;
    },
    [SettingsMutationTypes.SET_IS_PHABLET](state: State, isMobile: boolean) {
        state.isPhablet = isMobile;
    },
    [SettingsMutationTypes.SET_IS_TABLET](state: State, isMobile: boolean) {
        state.isTablet = isMobile;
    },
    [SettingsMutationTypes.SET_IS_PHONE](state: State, isMobile: boolean) {
        state.isPhone = isMobile;
    },
    [SettingsMutationTypes.SET_IS_PORTRAIT](state: State, isPortrait: boolean) {
        state.isPortrait = isPortrait;
    },
    [SettingsMutationTypes.SET_PROFILE](state: State, profile: string) {
        state.profile = profile;
    },
    [SettingsMutationTypes.SET_ONLINE](state: State, online: boolean) {
        state.online = online;
    },
    [SettingsMutationTypes.SET_IS_APP](state: State, isApp: boolean) {
        state.isApp = isApp;
    },
    [SettingsMutationTypes.SET_FIRST_VISIT_PRODUCT](state: State, firstVisitProduct: boolean) {
        state.firstVisitProduct = firstVisitProduct;
    },
    [SettingsMutationTypes.SET_CHOICES_AXEPTIO](state: State, choicesAxeptio: any|null) {
        state.choicesAxeptio = choicesAxeptio;
    },
};
