import { MutationTree } from 'vuex';

import {initialSate, State} from './state';
import { NoticesMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
    [NoticesMutationTypes.RESET](state: S): void;
    [NoticesMutationTypes.SET_NOTICES](state: S, payload: []): void;
}

export const mutations: MutationTree<State> & Mutations = {
    [NoticesMutationTypes.RESET](state: any) {
        const s: any = initialSate
        Object.keys(s).forEach((key: string) => state[key] = s[key])
    },
    [NoticesMutationTypes.SET_NOTICES](state: State, notices: []) {
        state.notices = notices;
    }
};
