import { ActionTree, ActionContext } from 'vuex';

import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { FavoritesMutationTypes } from './mutation-types';
import { FavoritesActionTypes } from './action-types';

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [FavoritesActionTypes.ADD_FAVORITES](
        { commit, state }: AugmentedActionContext,
        params: any
    ): void;
    [FavoritesActionTypes.REMOVE_FAVORITES](
        { commit, state }: AugmentedActionContext,
        params: any
    ): void;
    [FavoritesActionTypes.ADD_DISCONECT_FAVORITES](
        { commit, state }: AugmentedActionContext,
        params: any
    ): void;
    [FavoritesActionTypes.REMOVE_DISCONECT_FAVORITES](
        { commit, state }: AugmentedActionContext,
        params: any
    ): void;
    [FavoritesActionTypes.ADD_DISCONECT_VARIANTS](
        { commit, state }: AugmentedActionContext,
        params: any
    ): void;
    [FavoritesActionTypes.REMOVE_DISCONECT_VARIANTS](
        { commit }: AugmentedActionContext,
        params: any
    ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
    [FavoritesActionTypes.ADD_FAVORITES]({ commit, state }, product) {
        commit(FavoritesMutationTypes.SET_FAVORITES, [...state.favorites, product])
    },
    [FavoritesActionTypes.REMOVE_FAVORITES]({ commit, state }, product: any) {
        const favorites: any[] = state.favorites
        const indexFavorite: number = favorites.findIndex((favorite: any) => favorite.id === product.id && favorite.category === product.category)
        favorites.splice(indexFavorite, 1)
        commit(FavoritesMutationTypes.SET_FAVORITES, favorites)
    },
    [FavoritesActionTypes.ADD_DISCONECT_FAVORITES]({ commit, state }, favorite: number) {
        commit(FavoritesMutationTypes.SET_DISCONECT_FAVORITES, [...state.disconnectFavorites, favorite])
    },
    [FavoritesActionTypes.REMOVE_DISCONECT_FAVORITES]({ commit, state }, favorite: number) {
        const disconectFavorites: any[] = state.disconnectFavorites
        const indexFavorite: number = disconectFavorites.findIndex((disconectFavorites: number) => disconectFavorites === favorite)
        disconectFavorites.splice(indexFavorite, 1)
        commit(FavoritesMutationTypes.SET_DISCONECT_FAVORITES, disconectFavorites)
    },
    [FavoritesActionTypes.ADD_DISCONECT_VARIANTS]({ commit, state }, variant: number) {
        commit(FavoritesMutationTypes.SET_DISCONECT_VARIANTS, [...state.disconnectVariant, variant])
    },
    [FavoritesActionTypes.REMOVE_DISCONECT_VARIANTS]({ commit, state }, variant: number) {
        const disconectVariants: any[] = state.disconnectVariant
        const indexFavorite: number = disconectVariants.findIndex((disconectVariant: number) => disconectVariant === variant)
        if (indexFavorite > -1)
            disconectVariants.splice(indexFavorite, 1)
        commit(FavoritesMutationTypes.SET_DISCONECT_VARIANTS, disconectVariants)
    },
    [FavoritesActionTypes.SET_DISCONNECT_FAVORITES]({ commit }, favorites: number[]) {
        commit(FavoritesMutationTypes.SET_DISCONECT_FAVORITES, favorites)
    },
    [FavoritesActionTypes.SET_DISCONNECT_VARIANTS]({ commit }, variants: number[]) {
        commit(FavoritesMutationTypes.SET_DISCONECT_VARIANTS, variants)
    }
};
