import { ActionTree, ActionContext } from 'vuex';

import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { CatalogMutationTypes } from './mutation-types';
import { CatalogActionTypes } from './action-types';
import {api} from "@/plugins/api";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [CatalogActionTypes.FETCH_UNIVERSES](
        { commit }: AugmentedActionContext
    ): void;
    [CatalogActionTypes.FETCH_CATEGORIES](
        { commit }: AugmentedActionContext
    ): void;
    [CatalogActionTypes.FETCH_PRODUCTS](
        { commit }: AugmentedActionContext
    ): void;
    [CatalogActionTypes.PUSH_IN_HISTORY](
        { commit, state }: AugmentedActionContext,
        params: any
    ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [CatalogActionTypes.FETCH_UNIVERSES]({ commit }) {
        const {data} = await api.categories.index({depth: 3})
        commit(CatalogMutationTypes.SET_UNIVERSES, data.data.items)
    },
    async [CatalogActionTypes.FETCH_CATEGORIES]({ commit }) {
        const {data} = await api.categories.index()
        commit(CatalogMutationTypes.SET_CATEGORIES, data.data.items)
    },
    async [CatalogActionTypes.FETCH_PRODUCTS]({ commit }) {
        let next = true
        let page = null
        let perPage = null
        const products: [] = []
        while (next) {
            const {data}: any = await api.products.getListProducts({
                page,
                perPage
            })
            const dataItems: [] = data?.data?.items || []
            products.push(...dataItems)
            next = !!data?.data?.next.page
            page = data?.data?.next.page
            perPage = data?.data?.next.perPage
        }
        commit(CatalogMutationTypes.SET_PRODUCTS, products)
    },
    [CatalogActionTypes.PUSH_IN_HISTORY]({ commit, state }, product: any) {
        const history = [...state.history]
        const indexInHistory: number = history.findIndex(p => p.id === product.id)
        if (indexInHistory > -1)
            history.splice(indexInHistory, 1)
        history.unshift(product)
        if (history.length > 3)
            history.splice(3,1)
        commit(CatalogMutationTypes.SET_HISTORY, history)
    }
};
