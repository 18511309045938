import {Device} from '@capacitor/device';
import {Network} from '@capacitor/network';
import {useStore} from "@/store";
import {File} from "@ionic-native/file";
import {FileOpener} from "@ionic-native/file-opener";
import {InAppBrowser} from "@awesome-cordova-plugins/in-app-browser";
import {FileTransfer} from "@awesome-cordova-plugins/file-transfer"

/**
 * Service de gestion des fichiers en local
 * @class FileTransfer
 */
export default class FileTransferService {

    private subdirectory = 'offline/'
    private MIMETYPE_PNG = 'image/png';
    private MIMETYPE_JPEG = 'image/jpeg';
    private MIMETYPE_PDF = 'application/pdf';
    private STORAGE_API = process.env.VUE_APP_MEDIA_URL;
    private STORAGE_DEVICE_FOLDER = '';

    /**
     * Constructeur
     */
    constructor() {
        console.info("Init Service FileTransfer");
    }

    /**
     * Stocke les medias (img, pdf, ...) hors video en ligne.
     * @param listMedia
     * @param remove
     */
    public async storeMedia(listMedia: any, remove = false): Promise<any> {
        const promises: any[] | (void | Promise<any>)[] = [];
        const statusNetwork = await Network.getStatus();
        if (listMedia && statusNetwork.connected) {
            listMedia.forEach((media: any) => {
                if (remove) {
                    if (media.type === 'MEDIA') {
                        promises.push(this.deleteMediaOffline(media))
                    }
                } else {
                    if (media.type === 'IMG' || media.type === 'MEDIA') {
                        promises.push(this.addMediaOffline(media))
                    }
                }
            });
        }

        // update VScode ^1.57.0
        return Promise.allSettled(promises)
            .then((result: any[]) => {
                const listStatus = result.map((r) => r.status);
                if (listStatus.includes('fulfilled') || result?.length == 0) {
                    return true;
                } else {
                    throw new Error("Impossible de stocker ou supprimer les medias sur le device");
                }
            });
    }

    /**
     * Suppression des medias hors ligne
     * @param wsData
     */
    public removeMedia(wsData: any) {
        return this.storeMedia(wsData, true);
    }

    /**
     * Ajoute un media en local par téléchargement.
     * @param media
     */
    private addMediaOffline(media: MediaOffLine): Promise<any> | void {
        if (media.url.length > 0) {
            const uri = decodeURIComponent(media.url);
            const fileName = media.url;

            return this.downloadFile(this.STORAGE_API + uri, fileName).then((pathFull: any) => {
                if (this.STORAGE_DEVICE_FOLDER.length === 0 && pathFull?.length > 0) {
                    this.STORAGE_DEVICE_FOLDER = pathFull.substring(0, pathFull.indexOf(fileName));
                    localStorage.setItem('STORAGE_DEVICE_FOLDER', this.STORAGE_DEVICE_FOLDER);
                }
            }).catch((error) => {
                console.error(error);
            });
        }
    }

    /**
     * Suppression du media à partir de son path
     * @param media
     */
    private deleteMediaOffline(media: MediaOffLine): Promise<any> | void {
        if (media.url.length > 0) {
            // const uri = decodeURIComponent(media.url);
            const fileName = decodeURIComponent(media.url);
            return this.removeFile(fileName).catch((error) => {
                console.error(error);
            });
        }
    }

    /**
     * Télécharge un fichier en local.
     * @param fileUrl
     * @param fileName
     */
    public async downloadFile(fileUrl: string, fileName: string): Promise<any> {
        return new Promise((resolve) => {
            const fileTransfer = FileTransfer.create()
            const uri = encodeURI(fileUrl);
            const fileURL = File.dataDirectory + this.subdirectory + fileName;

            resolve(fileTransfer.download(
                uri,
                fileURL,
                false,
                {
                    headers: {
                        "Authorization": "Basic dGVzdHVzZXJuYW1lOnRlc3RwYXNzd29yZA=="
                    }
                }))
        });
    }

    /**
     * Ouvre un fichier sur le mobile ou navigateur
     * @param fileName
     * @param isMobile
     */
    public async openFile(fileName: string, isMobile = true) {
        const store = useStore()

        if (store.state.settings.online) {
            // ouverture du fichier depuis le navigateur
            if (isMobile) {
                InAppBrowser.create(fileName, '_system');
            } else {
                window.open(fileName, '_blank');
            }
        } else {
            // ouverture du fichier sur le disque du device
            await this.openFileOffline(fileName);
        }
    }

    /**
     * Ouvre un fichier sur le mobile.
     * @param fileName
     */
    private openFileOffline(fileName: string): Promise<any> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: ignore erreur
        const cordova: any = window['cordova'];
        const typeMime = this.getTypeMime(fileName);

        return new Promise((resolve, reject) => {

            if (cordova && fileName && typeMime) {
                const fileURL = File.dataDirectory + this.subdirectory + fileName;
                try {
                    resolve(FileOpener.open(fileURL, typeMime))
                } catch (e) {
                    reject(e)
                }
            } else {
                reject('No cordova or filename or type mime')
            }
        });
    }

    /**
     * Supprime un fichier.
     * @param fileName
     */
    public async removeFile(fileName: string): Promise<any> {
        if (fileName) {
            try {
                const dirEntry: any = await File.resolveLocalFilesystemUrl(File.dataDirectory + this.subdirectory)
                dirEntry.getFile(fileName, {create: false}, (fileEntry: any) => {
                    fileEntry.remove()
                })
            } catch (e) {
                console.log(e)
            }
        }
    }

    /**
     * Retourne les informations sur l'état de la mémoire
     * @returns Promise<Memory>
     */
    public async getInfoMemory(): Promise<Memory> {
        const idevice = await Device.getInfo();

        return new Promise((resolve, reject) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: ignore erreur
            const cordova: any = window['cordova'];

            cordova.exec(
                (result: number) => {
                    let freeDisk = -1;
                    if (result) {
                        if (idevice.platform === "ios") {
                            freeDisk = Math.floor(Math.floor(result / 1024) / 1024);
                            console.log('memory free : ' + freeDisk);
                        } else {
                            freeDisk = Math.floor(result / 1024);
                        }
                    }

                    const memory: Memory = {
                        freeDisk: freeDisk
                    };
                    resolve(memory);
                },
                (error: Error) => {
                    reject(error);
                },
                'File',
                'getFreeDiskSpace',
                []
            );
        });
    }

    /**
     * Retourne le type mime suivant l'extention du fichier.
     * @param fileName
     */
    private getTypeMime(fileName = ''): string {

        if (fileName.includes('.jpg') || fileName.includes('.jpeg')) {
            return this.MIMETYPE_JPEG;
        }
        if (fileName.includes('.pdf') || fileName.includes('.PDF')) {
            return this.MIMETYPE_PDF;
        }
        // type par défaut
        return this.MIMETYPE_PNG;
    }

    /**
     * Retourne le répertoire de stockage du device pour les média offline.
     */
    public getDeviceStoragePath() {
        return localStorage.getItem('STORAGE_DEVICE_FOLDER');
    }

}

export interface MediaOffLine {
    url: string;
    type: string;
}

export interface Memory {
    freeDisk: number;
}
