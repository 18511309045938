export default $axios => (resource, needToken = false) => ({
    getPages(params) {
        const config = {withToken: needToken, params}
        return $axios.get(`/${resource}`, config)
    },
    getPage(id, params) {
        const config = {withToken: needToken, params}
        return $axios.get(`/${resource}/${id}`, config)
    }
})
