export type State = {
    locale: string;
    siteId: number;
    isMobile: boolean;
    isPhablet: boolean;
    isTablet: boolean;
    isPhone: boolean;
    isPortrait: boolean;
    isLoading: boolean;
    locales: [];
    firstTimeApp: boolean;
    profile: string;
    online: boolean;
    isApp: boolean;
    firstVisitProduct: boolean;
    choicesAxeptio: any|null;
}

export const initialSate: State = {
    locale: 'fr',
    siteId: 1,
    isMobile: false,
    isPhablet: false,
    isTablet: false,
    isPhone: false,
    isPortrait: false,
    isLoading: false,
    locales: [],
    firstTimeApp: true,
    profile: '',
    online: false,
    isApp: false,
    firstVisitProduct: true,
    choicesAxeptio: null
}

export const state: State = initialSate;
