import {useStore} from "@/store";
import { isPlatform } from "@ionic/vue";

export default function hasProfile({next, router}: {next: any, router: any}) {
    const store = useStore()
    if (!store.state.settings.profile && isPlatform('desktop'))
        return router.push('/choix-profil')
    else if (!store.state.settings.profile && !isPlatform('desktop'))
        return router.push('/bienvenue')
    else
        return next()
}
