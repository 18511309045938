export enum SettingsMutationTypes {
    RESET = "RESET",
    SET_LOCALE = "SET_LOCALE",
    SET_LOCALES = "SET_LOCALES",
    SET_IS_LOADING = "SET_IS_LOADING",
    SET_IS_MOBILE = "SET_IS_MOBILE",
    SET_IS_PHABLET = "SET_IS_PHABLET",
    SET_IS_TABLET = "SET_IS_TABLET",
    SET_IS_PHONE = "SET_IS_PHONE",
    SET_IS_PORTRAIT = "SET_IS_PORTRAIT",
    SET_PROFILE = "SET_PROFILE",
    SET_ONLINE = "SET_ONLINE",
    SET_IS_APP = "SET_IS_APP",
    SET_FIRST_VISIT_PRODUCT = "SET_FIRST_VISIT_PRODUCT",
    SET_CHOICES_AXEPTIO = "SET_CHOICES_AXEPTIO"
}
