import { ActionTree, ActionContext } from 'vuex';

import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { NoticesMutationTypes } from './mutation-types';
import { NoticesActionTypes } from './action-types';
import {api} from "@/plugins/api";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [NoticesActionTypes.FETCH_NOTICES](
        { commit }: AugmentedActionContext,
        params: any
    ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [NoticesActionTypes.FETCH_NOTICES]({ commit }) {
        const {data} = await api.page.getPages({
            status: "PUBLISHED",
            type: "notice"
        })
        commit(NoticesMutationTypes.SET_NOTICES, data.data.items)
    },
};
