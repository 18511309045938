import { ActionTree, ActionContext } from 'vuex';

import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { SettingsMutationTypes } from './mutation-types';
import { SettingsActionTypes } from './action-types';

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [SettingsActionTypes.DEVICE_DETECTION](
        { commit }: AugmentedActionContext,
        params: {width: number; orientation: string}
    ): void;
    [SettingsActionTypes.PLATFORM_DETECTION](
        { commit }: AugmentedActionContext,
        params: {platform: string}
    ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [SettingsActionTypes.DEVICE_DETECTION]({ commit }, {width, orientation}) {
        commit(SettingsMutationTypes.SET_IS_MOBILE, (width <= 1024))
        commit(SettingsMutationTypes.SET_IS_TABLET, (width <= 1024 && width > 768))
        commit(SettingsMutationTypes.SET_IS_PHABLET, (width <= 768 && width > 565))
        commit(SettingsMutationTypes.SET_IS_PHONE, (width <= 565))
        commit(SettingsMutationTypes.SET_IS_PORTRAIT, (orientation === 'portrait'))
    },
    [SettingsActionTypes.PLATFORM_DETECTION]({commit}, {platform}) {
        commit(SettingsMutationTypes.SET_IS_APP, platform !== 'web')
    }
};
