export type State = {
    favorites: any[];
    disconnectFavorites: any[];
    disconnectVariant: any[];
}

export const initialSate: State = {
    favorites: [],
    disconnectFavorites: [],
    disconnectVariant: []
}

export const state: State = initialSate;
