import { MutationTree } from 'vuex';

import {initialSate, State} from './state';
import { FavoritesMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
    [FavoritesMutationTypes.RESET](state: S): void;
    [FavoritesMutationTypes.SET_FAVORITES](state: S, payload: any[]): void;
    [FavoritesMutationTypes.SET_DISCONECT_FAVORITES](state: S, payload: any[]): void;
    [FavoritesMutationTypes.SET_DISCONECT_VARIANTS](state: S, payload: any[]): void;
}

export const mutations: MutationTree<State> & Mutations = {
    [FavoritesMutationTypes.RESET](state: any) {
        const s: any = initialSate
        Object.keys(s).forEach((key: string) => state[key] = s[key])
    },
    [FavoritesMutationTypes.SET_FAVORITES](state: State, favorites: any[]) {
        state.favorites = favorites;
    },
    [FavoritesMutationTypes.SET_DISCONECT_FAVORITES](state: State, disconectFavorites: any[]) {
        state.disconnectFavorites = disconectFavorites;
    },
    [FavoritesMutationTypes.SET_DISCONECT_VARIANTS](state: State, disconnectVariant: any[]) {
        state.disconnectVariant = disconnectVariant;
    }
};
