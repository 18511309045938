import standardRepository from '@/repositories/api/_standard.js'
import productRepository from '@/repositories/api/product.js'
// import cartRepository from '@/repositories/api/cart.js'
import pageRepository from '@/repositories/api/page.js'
import searchRepository from '@/repositories/api/search.js'
import urlsRepository from '@/repositories/api/urls.js';
import VueAxios from "vue-axios";
import axios, {AxiosRequestConfig} from "axios";
import {useStore} from "@/store";
import {inject} from "vue";

export let api: any = {}
export default {
    install: (app: any) => {
        const store = useStore()

        app.use(VueAxios, axios)
        app.axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
            config.params = {
                ...config.params,
                lang: store.state.settings.locale,
                "site_id": store.state.settings.siteId
            }
            return config
        })

        app.axios.defaults.baseURL = process.env.VUE_APP_API_URL

        const standardRepositoryWithAxios = standardRepository(app.axios)
        const productRepositoryWithAxios = productRepository(app.axios)
        // const cartRepositoryWithAxios = cartRepository(app.axios, app.store)
        const pageRepositoryWithAxios = pageRepository(app.axios)
        const searchRepositoryWithAxios = searchRepository(app.axios)
        const urlsRepositoryWithAxios = urlsRepository(app.axios)

        const repositories = {
            address: standardRepositoryWithAxios('address'), // repository pour la gestion des addresses de customer
            products: productRepositoryWithAxios(), // repository pour les récupération des produits
            categories: standardRepositoryWithAxios('category', false), // repository pour les récupération de catégory
            contact: standardRepositoryWithAxios('contact', false), // repository pour les contacts
            articles: standardRepositoryWithAxios('page', false), // repository pour les pages de contenu
            menus: standardRepositoryWithAxios('menu', false), // repository pour les menus
            // cart: cartRepositoryWithAxios('cart'),
            page: pageRepositoryWithAxios('page', false),
            search: searchRepositoryWithAxios('search'),
            searchOffline: searchRepositoryWithAxios('search-offline'),
            settings: standardRepositoryWithAxios('setting', false),
            urls: urlsRepositoryWithAxios(),
            filters: standardRepositoryWithAxios('filters-products')
        }
        api = repositories
        app.provide('$api', repositories)
    }
}

export function useApi() {
    return inject('$api')
}
