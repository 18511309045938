export enum FavoritesActionTypes {
    ADD_FAVORITES = 'ADD_FAVORITES',
    REMOVE_FAVORITES = 'REMOVE_FAVORITES',
    SET_DISCONNECT_FAVORITES = 'SET_DISCONNECT_FAVORITES',
    SET_DISCONNECT_VARIANTS = 'SET_DISCONNECT_VARIANTS',
    ADD_DISCONECT_FAVORITES = 'ADD_DISCONECT_FAVORITES',
    REMOVE_DISCONECT_FAVORITES = 'REMOVE_DISCONECT_FAVORITES',
    ADD_DISCONECT_VARIANTS = 'ADD_DISCONECT_VARIANTS',
    REMOVE_DISCONECT_VARIANTS = 'REMOVE_DISCONECT_VARIANTS',
}
