export type State = {
    universes: any[];
    categories: any[];
    products: any[];
    history: any[];
    activeProductSlug: null|string
}

export const initialSate: State = {
    universes: [],
    categories: [],
    products: [],
    history: [],
    activeProductSlug: null
}

export const state: State = initialSate;
