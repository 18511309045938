import FileTransfer from './services/FileTransfer.service';

/**
 * Expose le service FileTransfer pour gérer les fichiers sur le device.
 * Important : ajouter la variable <<$file: FileTransfer;>> dans ComponentCustomProperties du fichier shims-vuex.d.ts
 * Usage :
 * this.$file.openFile('image.png');
 */
export default {
    install: (app: any) => {
        const file = new FileTransfer();
        app.provide('$file', file);
    }
}
