import { SQLite, SQLiteObject } from '@ionic-native/sqlite';
import { ref } from 'vue';

/**
 * Service de gestion des données en BDD (Sqlite)
 * @class Storage
 */
export default class Storage {

    /**
     * Instance de la base après ouverture.
     */
    private instanceDb: SQLiteObject | undefined;

    /**
     * Constructeur
     */
    constructor() {
        console.info("Init Service Storage");
        this.instanceDb = undefined;
    }

    /**
     * Initialise la creation et l'ouverture de la BDD
     * @return Promise
     */
    private async initDatabase() {
        const sqliteObject = ref<SQLiteObject>();

        try {
            this.instanceDb = await SQLite.create({
                name: 'claireDB',
                location: 'default',
                //key: "password"
            });

            sqliteObject.value = this.instanceDb;

            return this.instanceDb;
        } catch (e) {
            console.error('Unable to create database', e);
        }
    }

    /**
     * Retourne une instance unique de la base.
     * @return Promise
     */
    private async getInstanceDb() {
        if (this.instanceDb === undefined) {
            this.instanceDb = await this.initDatabase();
            // initialisation des tables
            await this.createTables();
        }

        if (this.instanceDb === undefined) {
            throw new Error('db instance unavailable');
        }

        return this.instanceDb;
    }

    /**
     * Creation des tables de l'application
     */
    private async createTables() {
        const promisesRequest = [
            this.request("CREATE TABLE IF NOT EXISTS Product (id BIGINT not null, productData text null, constraint PK_Product primary key (id));", []),
            this.request("CREATE TABLE IF NOT EXISTS Category (id BIGINT not null, categoryData text null, constraint PK_Category primary key (id));", []),
            this.request("CREATE TABLE IF NOT EXISTS Notice (id BIGINT not null, noticeData text null, constraint PK_Notice primary key (id));", []),
            this.request("CREATE TABLE IF NOT EXISTS Universe (id BIGINT not null, universeData text null, constraint PK_Universe primary key (id));", []),
        ]
        await Promise.all(promisesRequest)
    }

    /**
     * Méthode pour exécuter une requête SQL.
     * @param request 
     * @param values 
     * @return Promise
     */
    public request(request: string, values: any[]): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                this.getInstanceDb().then(db => {
                    db.transaction((tx: any) => {
                        tx.executeSql(request,
                            values,
                            (transaction: any, tables: any) => {
                                if (tables) {
                                    // mise ne forme des resultats du SELECT
                                    if (tables.rowsAffected === 0) {
                                        const cache = [];
                                        for (let i = 0; i < tables.rows.length; i = i + 1) {
                                            cache.push(tables.rows.item(i));
                                        }
                                        resolve(cache);
                                    } else {
                                        // autre requête
                                        resolve(tables);
                                    }
                                }
                            },
                            (error: any) => {
                                //console.log('executeSql ERROR: ' + error.message);
                                reject(error);
                            },
                        );
                    });
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    /**
     * Ajoute un produit en BDD.
     * @param product
     * @param idProduct
     */
    public addProductOffline(idProduct: number, product: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (product) {
                // stockage complet au format texte de l'objet produit.
                // permet de garantir les evolutions du modele objet du produit.
                product = JSON.stringify(product);

                return this.request(
                    'INSERT INTO Product VALUES (?,?)', [idProduct, product]
                )
                .then(resolve)
                .catch(() => reject(new Error('Impossible d\'ajouter le produit en mode hors connection')));
            }
        });
    }

    /**
     * Ajoute une catégorie en BDD.
     * @param category
     * @param idCategory
     */
    public addCategoryOffline(idCategory: number, category: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (category) {
                // stockage complet au format texte de l'objet produit.
                // permet de garantir les evolutions du modele objet du produit.
                category = JSON.stringify(category);

                return this.request(
                    'INSERT INTO Category VALUES (?,?)', [idCategory, category]
                )
                    .then(resolve)
                    .catch(() => reject(new Error('Impossible d\'ajouter la categorie en mode hors connection')));
            }
        });
    }

    /**
     * Ajoute une notice en BDD.
     * @param notice
     * @param idNotice
     */
    public addNoticeOffline(idNotice: number, notice: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (notice) {
                // stockage complet au format texte de l'objet produit.
                // permet de garantir les evolutions du modele objet du produit.
                notice = JSON.stringify(notice);

                return this.request(
                    'INSERT INTO Notice VALUES (?,?)', [idNotice, notice]
                )
                    .then(resolve)
                    .catch(() => reject(new Error('Impossible d\'ajouter la notice en mode hors connection')));
            }
        });
    }

    /**
     * Ajout de l'arborescence univers en BDD.
     * @param universe
     * @param idUniverse
     */
    public addUniverseOffline(idUniverse: number, universe: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (universe)
            {
                // stockage complet au format texte de l'objet produit.
                // permet de garantir les evolutions du modele objet du produit.
                universe = JSON.stringify(universe);

                return this.request(
                    'INSERT INTO Universe VALUES (?,?)', [idUniverse, universe]
                )
                    .then(resolve)
                    .catch(() => reject(new Error('Impossible d\'ajouter l\'arborescence univers en mode hors connection')));
            }
        });
    }

    /**
     * Ajoute une liste de produits en BDD.
     * @param products 
     */
    public addProductsListOffline(products: any[]): Promise<any> {
        
        const promises: Promise<any>[] = [];
        if (products && products.length > 0) {            
            products.forEach((p: any) => {
                promises.push(this.addProductOffline(p.id, p));
            });
        }
        return Promise.all(promises);
    }

    /**
     * Ajoute une liste des catégories en BDD.
     * @param categories
     */
    public addCategoriesListOffline(categories: any[]): Promise<any> {

        const promises: Promise<any>[] = [];
        if (categories?.length > 0) {
            categories.forEach((category: any) => {
                promises.push(this.addCategoryOffline(category.id, category));
            });
        }
        return Promise.all(promises);
    }

    /**
     * Ajoute une liste des pages notices en BDD.
     * @param notices
     */
    public addNoticesListOffline(notices: any[]): Promise<any> {

        const promises: Promise<any>[] = [];
        if (notices?.length > 0) {
            notices.forEach((notice: any) => {
                promises.push(this.addNoticeOffline(notice.id, notice));
            });
        }
        return Promise.all(promises);
    }

    /**
     * Ajoute une liste des univers en BDD.
     * @param universes
     */
    public addUniversesListOffline(universes: any[]): Promise<any> {

        const promises: Promise<any>[] = [];
        if (universes?.length > 0) {
            universes.forEach((universe: any) => {
                promises.push(this.addUniverseOffline(universe.id, universe));
            });
        }
        return Promise.all(promises);
    }

    /**
     * Retourne tous les produits en BDD (offline).
     */
    public getAllProducts(): Promise<any[]> {
        return this.request(
            'SELECT * FROM Product', []
        )
        .then((resultSelect => {
            const products: any[] = [];
            if (resultSelect && resultSelect.length > 0) {                
                resultSelect.forEach((element: {id: number; productData: any}) => {
                    // retourne le produit en mode objet
                    products.push(JSON.parse(element.productData));
                });
            }
            return products;
        }));
    }

    /**
     * Retourne tous les categories en BDD (offline).
     */
    public getAllCategories(): Promise<any[]> {
        return this.request(
            'SELECT * FROM Category', []
        )
            .then((resultSelect => {
                const categories: any[] = [];
                if (resultSelect && resultSelect.length > 0) {
                    resultSelect.forEach((element: {id: number; categoryData: any}) => {
                        // retourne le produit en mode objet
                        categories.push(JSON.parse(element.categoryData));
                    });
                }
                return categories;
            }));
    }

    /**
     * Retourne tous les categories en BDD (offline).
     */
    public getAllNotices(): Promise<any[]> {
        return this.request(
            'SELECT * FROM Notice', []
        )
            .then((resultSelect => {
                const notices: any[] = [];
                if (resultSelect && resultSelect.length > 0) {
                    resultSelect.forEach((element: {id: number; noticeData: any}) => {
                        // retourne le produit en mode objet
                        notices.push(JSON.parse(element.noticeData));
                    });
                }
                return notices;
            }));
    }

    /**
     * Retourne tous les categories en BDD (offline).
     */
    public getAllUniverses(): Promise<any[]> {
        return this.request(
            'SELECT * FROM Universe', []
        )
            .then((resultSelect => {
                const universes: any[] = [];
                if (resultSelect && resultSelect.length > 0) {
                    resultSelect.forEach((element: {id: number; universeData: any}) => {
                        // retourne le produit en mode objet
                        universes.push(JSON.parse(element.universeData));
                    });
                }
                return universes;
            }));
    }

    /**
     * Truncate table data
     * @param table
     */
    public truncateTable(table: string): Promise<any> {
        return this.request(
            `DELETE FROM ${table};`, []
        )
    }

    /**
     * Retourne le produit en BDD (offline) selon son id.
     * @param id 
     */
    public getProductById(id: number): Promise<any> {
        return this.request(
            'SELECT * FROM Product WHERE id=' + id, []
        )
        .then((resultSelect => {
            let product: any;
            if (resultSelect && resultSelect.length > 0) {                
                resultSelect.forEach((element: {id: number; productData: any}) => {
                    // retourne le produit en mode objet
                    product = JSON.parse(element.productData);
                });
            }
            return product;
        }));
    }

    /**
     * Supprime le produit en BDD (offline) selon son id.
     * @param id 
     */
    public removeProductById(id: number): Promise<any> {
        
        return this.request(
            'DELETE FROM Product WHERE id=' + id, []
        );
    }

    /**
     * Supprime les produits en BDD (offline) selon la liste d'id.
     * @param id 
     */
    public removeProductsListById(ids: number[]): Promise<any> {
        
        const promises: Promise<any>[] = [];
        if (ids && ids.length > 0) {            
            ids.map((id: number) => {
                promises.push(this.removeProductById(id));
            });
        }
        return Promise.all(promises);
    }
}
