import { MutationTree } from 'vuex';

import {initialSate, State} from './state';
import { CatalogMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
    [CatalogMutationTypes.RESET](state: S): void;
    [CatalogMutationTypes.SET_UNIVERSES](state: S, payload: []): void;
    [CatalogMutationTypes.SET_CATEGORIES](state: S, payload: []): void;
    [CatalogMutationTypes.SET_PRODUCTS](state: S, payload: []): void;
    [CatalogMutationTypes.SET_HISTORY](state: S, payload: any[]): void;
    [CatalogMutationTypes.SET_ACTIVE_PRODUCT_SLUG](state: S, payload: null|string): void;
}

export const mutations: MutationTree<State> & Mutations = {
    [CatalogMutationTypes.RESET](state: any) {
        const s: any = initialSate
        Object.keys(s).forEach((key: string) => state[key] = s[key])
    },
    [CatalogMutationTypes.SET_UNIVERSES](state: State, universes: []) {
        state.universes = universes;
    },
    [CatalogMutationTypes.SET_CATEGORIES](state: State, categories: []) {
        state.categories = categories;
    },
    [CatalogMutationTypes.SET_PRODUCTS](state: State, products: []) {
        state.products = products;
    },
    [CatalogMutationTypes.SET_HISTORY](state: State, history: any[]) {
        state.history = history
    },
    [CatalogMutationTypes.SET_ACTIVE_PRODUCT_SLUG](state: State, slug: null|string) {
        state.activeProductSlug = slug
    }
};
