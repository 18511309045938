import Storage from './services/Storage.service';
/**
 * Expose le service Storage pour gérer les requêtes dans la base SQLITE.
 * Important : ajouter la variable <<$db: Storage;>> dans ComponentCustomProperties du fichier shims-vuex.d.ts
 * Usage :
 * this.$db.request('INSERT INTO DemoTable VALUES (?,?,?)', ['1', 'Martin', '100'])
 */
export default {
    install: (app: any) => {
        const db = new Storage();
        app.provide('$db', db);
    }
}
