export default ($axios) => () => ({
    show(id) {
        return $axios.get(`/product/${id}`)
    },
    async getListProducts(params) {
        return await $axios.get(`/product`, {params})
    },
    async tinyUrlToUrl(tinyUrl) {
        return await $axios.get(`/product/url?tinyUrl=${tinyUrl}`)
    }
})
